declare global {
    interface Window {
        dataLayer?: object[];
    }
}

interface DataLayerObject {
    event: string;
    [data: string]: string;
}

const pushToDataLayer = (obj: DataLayerObject) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
};

const pushContactDetails = (el: HTMLElement, dataCallback: (link: HTMLLinkElement) => DataLayerObject)=> {
    return el.querySelectorAll<HTMLLinkElement>('a:where([href^="tel:"], [href^="mailto:"])')
        .forEach(link => {
            link.addEventListener("click", () => pushToDataLayer({
                ...dataCallback(link),
                type: link.href?.startsWith('tel') ? 'phone' : 'email',
                contact: link.innerText
            }))
        })
}

const initDatalayer = () => {
    (() => {
        const mapSection = document.querySelector('[data-showroom-map]')?.closest('section')
        mapSection && pushContactDetails(mapSection, link => ({
            event: 'Proklik kontaktu u mapy'
        }))

        const teamGrid = document.querySelector<HTMLElement>('.TeamGrid')
        teamGrid && pushContactDetails(teamGrid, link => ({
            event: 'Proklik kontaktu z týmu',
            name: link.closest('div')?.previousElementSibling.querySelector('.wp-block-heading')?.innerHTML ?? ''
        }))

        const interestModal = document.querySelector<HTMLElement>('dialog#interest')
        interestModal && pushContactDetails(interestModal, link => ({
            event: 'Proklik kontaktu z modalu'
        }))

        const sellerContact = document.querySelector<HTMLElement>('.ContactSeller')
        sellerContact && pushContactDetails(sellerContact, link => ({
            event: 'Proklik kontaktu prodejce',
            name: link.closest('div')?.querySelector('h3')?.innerHTML ?? ''
        }))
    })();

    document.addEventListener("DOMContentLoaded", () => {
        // @ts-ignore
        window.hasOwnProperty('html_forms') && html_forms?.on('success', (formElement: HTMLFormElement) => {
            pushToDataLayer({
                event: 'form-send',
                formID: formElement.dataset.slug,
                pageUrl: window.location.href
            })
        });
    });
}

export default initDatalayer