
import initLightbox from "./components/lightbox";
import {initMap} from "./components/map";
import initMotion from "./components/motion";
import initSwiper from "./components/swiper";
import initModal from "./components/modal";
import initVideo from "./components/video";
import {inView} from "motion";
import initPagination from "./components/pagination";
import initDatalayer from "./components/datalayer";

import.meta.glob([ '@images/**' ])
import.meta.env.DEV && import("@styles/main.sass");

(() => {
    // TODO: doplnit aria attributy
    const header = document.querySelector<HTMLElement>('[data-header]'),
        menu = header?.querySelector('dialog'),
        models = header?.querySelector('ul[popover]')

    const opener = header.querySelector('[data-menu-opener]')
    opener?.addEventListener("click", () => menu.showModal())

    const closer = menu.querySelector('[data-menu-opener]')
    closer?.addEventListener("click", () => menu.close())
    closer?.setAttribute('autofocus', '')

    models?.addEventListener("beforetoggle", (event: ToggleEvent) => {
        if (event.oldState === "closed" && !menu.open) {
            menu.setAttribute('data-model-view', 'true');
            menu.showModal()
        }

        if (event.oldState === "open" && menu.dataset.modelView) {
            menu.close();
            menu.removeAttribute('data-model-view');
        }
    })
})();

(async () => {
    initModal();
    initMotion();
    initDatalayer();
    await initVideo();
    await initLightbox();
    await initPagination();
    await initSwiper();

    const mapEl = document.querySelector<HTMLElement>("[data-showroom-map]")
    mapEl && inView(mapEl, () => { initMap(mapEl) })
})();
