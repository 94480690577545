import {callOnce} from "../utils/call-once";

const initVideo = async () => {
    const videos = document.querySelectorAll<HTMLElement>('[data-video]:has(video)')
    if (!videos.length) return

    videos.forEach(videoHolder => {
        const video = videoHolder.querySelector('video')

        const playVideo = () => {
            if (!!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)) return

            video.play().then(() => {
                video.parentElement.dataset.video = "is-playing"
            }).catch(() => {
                video.parentElement.dataset.video = "not-allowed"
            })
        }

        if (videoHolder.dataset.autoplay) {
            callOnce(video, "loadedmetadata", playVideo)
            callOnce(video, "canplay", playVideo)

            video.readyState > 0 && playVideo()
        }

        video.addEventListener("playing", () => {
            video.parentElement.dataset.video = "is-playing"
        })

        video.addEventListener("ended", () => {
            video.parentElement.dataset.video = ""
        })
    })
}

export default initVideo