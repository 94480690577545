import Lenis from "lenis";
import {animate, frame, inView, scroll, stagger} from "motion";
import ImageSequence from "../utils/image-sequence";

const initMotion = () => {
    const smoothScroll = new Lenis({ anchors: { offset: -40 } }),
        raf = time => smoothScroll.raf(time.timestamp)

    frame.update(raf, true);

    (() => {
        const homeIntro = document.querySelector<HTMLVideoElement>('[data-home-intro]'),
            homeLoop = document.querySelector<HTMLVideoElement>('[data-home-loop]'),
            header = document.querySelector<HTMLElement>('[data-header]')

        if (!homeLoop) return
        if (window.scrollY > 80) header.style.opacity = '1'

        animate([
            [ header, { opacity: 1 }, { duration: homeIntro ? 1.6 : .4 } ],
            [ ".HomeHeroCover > div", { transform: ["translateY(-2.5rem)"] }, { duration: .8, at: '-.4', } ],
            [ ".HomeHeroIntro small", { filter: "opacity(1)" }, { at: '-.4', duration: .4 } ],
        ], { delay: homeIntro ? 2.4 : 0 })

        scroll(animate(".HomeHeroCover", { transform: "translateY(-100%)" }), {
            target: homeLoop,
            offset: ["end end", "end start"]
        })

        homeIntro?.addEventListener("ended", () => {
            homeLoop.play().then(() => homeIntro.parentElement.classList.add('u-hidden'))
        })

        const fade = animate(".HomeHeroIntroduction p", {
            opacity: [0, 1],
            transform: ["translateY(0)", "translateY(-20vh)"]
        }, {
            delay: stagger(.12),
            duration: .12,
            transform: { duration: 1 }
        })

        scroll(fade, {
            target: document.querySelector(".HomeHeroIntroduction"),
            offset: ["start 80vh", "end start"]
        })
    })();

    (async () => {
        const showroomWalkthrough = document.querySelector<HTMLElement>("[data-showroom-walkthrough]"),
            cover = showroomWalkthrough?.querySelector('picture'),
            canvas = showroomWalkthrough?.querySelector('canvas');

        if (!showroomWalkthrough) return;
        const imageSequence = new ImageSequence(canvas)

        inView(cover, () => {
            if (!imageSequence.isLoaded) {
                smoothScroll.stop()
                smoothScroll.scrollTo(showroomWalkthrough.offsetTop, { duration: 0, force: true })
            }

            //animate(showroomWalkthrough.querySelector('aside'), { y: [ '200%', 0 ]})
        }, {
            amount: "all"
        })

        const fadeCoverSticky = animate(cover, { opacity: [0, 1], transform: ["translateY(-12vh)", "translateY(0)"] }, { duration: 4 }),
            cancelStickyCover = scroll(fadeCoverSticky, {
            target: showroomWalkthrough,
            offset: ["start 80vh", "start 40vh"]
        })

        const setupWalkthrough = () => {
            console.log('once')
            imageSequence.loadFrames().then(() => {
                cancelStickyCover();

                const fadeCoverFull = animate([
                    [ cover, { opacity: [0, 1], transform: ["translateY(-12vh)", "translateY(0)"] }, { duration: 4 } ],
                    [ cover, { opacity: [1, 0] }, { duration: 2, at: 8 } ],
                    [ canvas, { opacity: [0, 1] }, { duration: 2, at: '<' } ],
                    [ cover.querySelector('img'), { transform: ["scale(1)", "scale(1.12)"] }, { duration: 2, at: '<' } ]
                ])

                scroll(fadeCoverFull, {
                    target: showroomWalkthrough,
                    offset: ["start 80vh", "120vh end"]
                })

                smoothScroll.isStopped && smoothScroll.start()
                inView(cover, () => smoothScroll.scrollTo(showroomWalkthrough.offsetTop + window.innerHeight * .24, {
                    duration: 2.4
                }), {
                    margin: "0px 0px -80% 0px"
                })

                imageSequence.loadFrames();
            })

            const anchors = Array.from(showroomWalkthrough.querySelectorAll<HTMLButtonElement>('aside nav > button') ?? []);

            scroll((progress: number) => {
                const frameIndex = Math.floor(progress * (imageSequence.frames.length - 1));
                if (!imageSequence.isLoaded || imageSequence.currentFrame === frameIndex) return;

                imageSequence.drawFrame(frameIndex);

                const active = Math.max(1, Math.min(Math.round(progress * 6), anchors.length))
                anchors.forEach(anchor => anchor.classList.toggle('is-active', anchors.indexOf(anchor) + 1 === active))
            }, {
                target: showroomWalkthrough,
                offset: ["100vh end", "440vh end"]
            });

            anchors.forEach(anchor => anchor.addEventListener("click", () => {
                !anchor.classList.contains('is-active')
                && smoothScroll.scrollTo(showroomWalkthrough.offsetTop + ((showroomWalkthrough.offsetHeight - window.innerHeight) / 5 * (anchors.indexOf(anchor) + .4)), { duration: 2.4 })
            }))
        }

        inView(showroomWalkthrough, () => setupWalkthrough(), {
            margin: "0px 0px -50% 0px"
        })

        showroomWalkthrough.querySelector('[data-skip-walkthrough]')?.addEventListener('click', () => {
            smoothScroll.isStopped && smoothScroll.start()
            smoothScroll.scrollTo(showroomWalkthrough.offsetTop + showroomWalkthrough.offsetHeight - window.innerHeight)
        })
    })();

    (() => {
        const modelsPresentation = document.querySelector<HTMLElement>('[data-models-presentation]'),
            slides = Array.from(modelsPresentation?.querySelectorAll<HTMLElement>('[data-model-slide]') ?? [])

        if (!modelsPresentation) return

        const fadeIn = animate([
            [ modelsPresentation, { opacity: 1 } ],
            [ slides[0].querySelector('img'), { transform: ["scale(.88)", "scale(1)"] }, { at: '<' } ]
        ])

        inView(slides[0].parentElement, () => {
            modelsPresentation.classList.add('in-view')

            return () => modelsPresentation.classList.remove('in-view')
        }, {
            amount: "all"
        })

        scroll(fadeIn, {
            target: modelsPresentation,
            offset: ["100vh end", "120vh end"]
        })

        const virtualHeight = parseInt(getComputedStyle(modelsPresentation).getPropertyValue('--ModelSlideVirtualHeight').replace('vh', '')),
            totalHeight = 100 + 4 * virtualHeight

        scroll((progress:number) => {
            const active = Math.max(1, Math.min(Math.round(progress * 4), slides.length))
            slides.forEach(slide => slide.classList.toggle('is-visible', slides.indexOf(slide) + 1 === active))
        }, {
            target: modelsPresentation,
            offset: ["100vh end", `${totalHeight}vh end`]
        })

        scroll(animate(modelsPresentation, { clipPath: "inset(0 0 100vh)" }), {
            target: modelsPresentation,
            offset: [`${totalHeight}vh end`, `${totalHeight + 50}vh end`]
        })
    })();

    (() => {
        const bespokeIntroduction = document.querySelector('[data-bespoke-introduction]'),
            universeHolder = document.querySelector('[data-universe]')

        universeHolder && inView(universeHolder.parentElement, () => {
            for (let i = 0; i < (window.innerWidth > 960 ? 240 : 120); i++) {
                const star = document.createElement('star')
                star.style.top = `${100 * Math.random()}%`
                star.style.left = `${100 * Math.random()}%`

                universeHolder.appendChild(star)
            }

            const shineStar = (star: HTMLElement) => {
                return animate(star, { opacity: Math.random() }, { duration: Math.random() * .5 + .5 })
                    .then(() => shineStar(star))
            }

            universeHolder.querySelectorAll<HTMLElement>('star').forEach(star => shineStar(star))
        })

        if (!bespokeIntroduction) return

        const fadeIn = animate([
            [ universeHolder, { opacity: [0, 1] }, { duration: .2 } ],
            [ ".BespokeIntroductionInner :is(h2, p, [data-button-holder])", { opacity: [0, 1], transform: ["translateY(2rem)", "translateY(0)"] }, { delay: stagger(.08) }],
            [ ".BespokeIntroductionInner", { transform: ["translateY(0)", "translateY(-50%)"] }, { at: '+.4', duration: .8 } ],
            [ ".BespokeIntroductionAtelier", { clipPath: 'inset(0)' }, { at: '<', duration: .8 } ],
            [ ".BespokeIntroductionAtelier > img", { transform: ["translateY(-16vh)", "translateY(0)"] }, { at: '<', duration: 1.32 } ]
        ])

        scroll(fadeIn, {
            target: bespokeIntroduction,
            offset: ["100vh end", "end start"]
        })
    })();
}

export default initMotion