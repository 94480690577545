const initPagination = async () => {
    if (!document.body.classList.contains('blog')) return

    const { Livewire, Alpine } = await import("../../../vendor/livewire/livewire/dist/livewire.esm");

    const postArchive = document.querySelector('[data-post-archive]')

    Alpine.store('postArchiveStack', [])

    Livewire.on('load-more-posts', () => postArchive.querySelectorAll('article:not([data-key])')
        .forEach(el => Alpine.store('postArchiveStack').push(el.outerHTML.replace('wire:', 'data-'))))

    Livewire.on('change-archive-page', () => {
        Alpine.store('postArchiveStack', [])
        postArchive?.scrollIntoView()
    })

    Livewire.on('change-archive-url', (details: { url: string }) => history.replaceState({}, '', details.url))
    Livewire.start()
}

export default initPagination