class ImageSequence {
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;

    public frameUrlPattern: string;
    public frames: HTMLImageElement[];

    public loadedFrames: number = 0;
    public currentFrame: number;

    private framesRange: number;
    private isFetching: boolean = false;

    get isLoaded() {
        return this.loadedFrames > this.frames.length / 2
    }

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;

        this.setup().then(() => {
            this.context = this.canvas.getContext('2d');
            this.frames = new Array(parseInt(canvas.dataset.framesCount))
            this.framesRange = canvas.dataset.framesCount.length
        })
    }

    async setup() {
        const viewportWidth = window.innerWidth * Math.min(window.devicePixelRatio ?? 1, 2)
        let avifSupported = localStorage.getItem('avif-supported')
        if (avifSupported === null) {
            const avifBlob = await fetch('data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUEAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAABYAAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAEAAAABAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgSAAAAAAABNjb2xybmNseAACAAIABoAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB5tZGF0EgAKBzgADlAgIGkyCR/wAABAAACvcA==').then((r) => r.blob())

            avifSupported = await createImageBitmap(avifBlob).then(() => 'true').catch(() => 'false')
            localStorage.setItem('avif-supported', avifSupported)
        }

        this.frameUrlPattern = this.canvas.dataset.url
            .replace('${size}', ([640, 1024, 1440, 1920].find(size => viewportWidth < size) ?? 2560).toString())
            .replace('.jpg', !!avifSupported ? '.avif' : '.jpg')
    }

    loadFrame(index: number, resolve) {
        if (this.frames[index - 1])
            return void (resolve && resolve())

        const image = new Image;
        const unpackFrame = () => {
            resolve && resolve()
            image.removeEventListener("load", unpackFrame);

            this.frames[index - 1] = image;
            this.loadedFrames++;
        }

        image.src = this.frameUrlPattern.replace('${frame}', index.toString().padStart(this.framesRange, '0'));
        image.addEventListener("load", unpackFrame)
    }

    async loadFrames() {
        if (this.isFetching) return
        this.toggleFetching(true)

        const queue = [],
            queueStart = this.loadedFrames + 1

        for (let index = queueStart; index <= Math.min(this.loadedFrames + 128, this.frames.length); index++) {
            const request = new Promise((resolve => {
                this.loadFrame(index, resolve)
            }))

            queue.push(request)
        }

        return Promise.all(queue).then(() => {
            this.toggleFetching(false)
            if (queueStart !== 1) return

            this.canvas.height = this.frames[0].height;
            this.canvas.width = this.frames[0].width;
            this.drawFrame(0);
        })
    }

    drawFrame(index: number) {
        if (!this.frames[index]) return

        this.context.drawImage(this.frames[index], 0, 0);
        this.currentFrame = index;
    }

    private toggleFetching = (state: boolean = !this.isFetching) => {
        this.isFetching = state

        const showroomWalkthrough = this.canvas.closest('section');
        showroomWalkthrough.toggleAttribute('data-fetching', this.isFetching);
        !this.isFetching && showroomWalkthrough.setAttribute('data-loaded', '');
    }
}

export default ImageSequence